import dayjs from 'dayjs'
import { useState } from 'react'
import { useInterval } from './useInterval'

export const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState(dayjs())

  useInterval({
    executor: () => {
      setCurrentTime(dayjs())
    },
  })

  return currentTime
}
