import { SuiteApi } from '@boommed-suite/contracts'
import { Box, Divider, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { ReactNode } from 'react'
import {
  CurrentTimeProvider,
  useCurrentTimeContext,
} from '../../../../app/contexts/CurrentTimeContext'
import { CustomPalette } from '../../../../app/Theme'

interface HospitalizationScheduleCell {
  timestamp?: number
  value?: ReactNode
}

interface HospitalizationScheduleProps {
  treatments: SuiteApi.HospitalizationTreatmentResponse[]
}

function ScheduleCell({
  hour,
  children,
}: {
  hour: number
  children: React.ReactNode
}) {
  const { currentTime } = useCurrentTimeContext()

  const isCurrentHour = hour === currentTime.hour()

  const backgroundColor =
    hour % 2 === 0 ? CustomPalette.master.lightGrey : CustomPalette.master.white

  const minutesPercentage = currentTime.minute() / 60

  return (
    <Stack
      width="29px"
      height="40px"
      flexGrow={1}
      sx={{
        backgroundColor,
      }}
    >
      <Stack direction="row" width="100%">
        {isCurrentHour ? (
          <Box
            width="2px"
            height="41px"
            style={{
              backgroundColor: CustomPalette.master.primary.main,
              position: 'relative',
              left: `${minutesPercentage * 100}%`,
              zIndex: 2,
            }}
          >
            &nbsp;
          </Box>
        ) : null}
        <Box
          alignContent="center"
          height="40px"
          textAlign="center"
          fontSize="13px"
          width="100%"
          sx={{
            zIndex: 1,
          }}
        >
          {children}
        </Box>
      </Stack>
    </Stack>
  )
}

function ScheduleRow({
  title,
  values,
  header = false,
}: {
  title: string
  values: HospitalizationScheduleCell[]
  header?: boolean
}) {
  const cellPlaceholders: ReactNode[][] = new Array(24).fill(0).map(() => [])

  values.forEach((value) => {
    const hour = dayjs(value.timestamp).hour()

    cellPlaceholders[hour].push(value?.value)
  })

  return (
    <Stack direction="row" minWidth="804px" height="40px">
      <Box>
        <Typography
          width="108px"
          variant="h5"
          fontWeight={header ? 600 : 400}
          height="40px"
          alignContent="center"
          textAlign="center"
        >
          {title}
        </Typography>
      </Box>
      {cellPlaceholders.map((values, i) => (
        <ScheduleCell key={i} hour={i}>
          {values.length === 1 ? values[0] : null}
          {values.length > 1 ? values.length : null}
          {values.length === 0 ? null : null}
        </ScheduleCell>
      ))}
    </Stack>
  )
}

function ScheduleHeader() {
  const { currentTime } = useCurrentTimeContext()

  const hours: HospitalizationScheduleCell[] = new Array(24)
    .fill(0)
    .map((_, i) => ({
      timestamp: currentTime.hour(i).minute(0).second(0).valueOf(),
      value: [
        <Typography key={i} fontWeight={600} fontSize="13px">
          {i}
        </Typography>,
      ],
    }))

  return (
    <ScheduleRow
      title={currentTime.format('HH:mm:ss')}
      values={hours}
      header={true}
    />
  )
}

export function HospitalizationSchedule({
  treatments,
}: HospitalizationScheduleProps) {
  return (
    <CurrentTimeProvider>
      <Stack width="100%" divider={<Divider flexItem />}>
        <ScheduleHeader />
        {treatments.map((treatment, i) => (
          <ScheduleRow
            key={i}
            title={treatment.title}
            values={treatment.values}
          />
        ))}
        <Divider flexItem />
      </Stack>
    </CurrentTimeProvider>
  )
}
