import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import {
  Controller,
  FieldError,
  FieldValues,
  Path,
  useFormContext,
} from 'react-hook-form'

export type CustomFormAutoCompleteOnChangeHandler<T> = (
  value: T | readonly T[] | null,
) => void

export function CustomFormAutoComplete<
  OptionsType,
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  label,
  loadingText,
  noOptionsText,
  getOptionLabel,
  isOptionEqualToValue,
  renderOption,
  options,
  onChange,
  required = false,
  multiple = false,
  disableCloseOnSelect = false,
}: {
  name: Path<TFieldValues>
  label?: string
  loadingText?: string
  noOptionsText?: string
  getOptionLabel: (option: OptionsType) => string
  isOptionEqualToValue: (option: OptionsType, value: OptionsType) => boolean
  renderOption: (
    props: React.HTMLAttributes<HTMLLIElement> & {
      key: unknown
    },
    option: OptionsType,
  ) => React.ReactNode
  options?: OptionsType[]
  onChange?: CustomFormAutoCompleteOnChangeHandler<OptionsType>
  required?: boolean
  multiple?: boolean
  disableCloseOnSelect?: boolean
}) {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>()

  const error = errors[name] as FieldError | undefined

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...register}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              label={label}
              helperText={error?.message ?? ''}
              error={Boolean(error)}
            />
          )}
          options={options ?? []}
          loadingText={loadingText}
          noOptionsText={noOptionsText}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(_, value) => {
            field.onChange(value)
            onChange?.(value)
          }}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          multiple={multiple}
          disableCloseOnSelect={disableCloseOnSelect}
        />
      )}
    />
  )
}
