import { Strings } from '@boommed-suite/typescript-crossplatform'
import { mdiCakeVariant, mdiDog, mdiScale } from '@mdi/js'
import Icon from '@mdi/react'
import { Avatar, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../app/i18n/i18n'
import { useHospitalizationContext } from '../../HospitalizationContext'

const formatAge = (birthday: number) => {
  const now = dayjs()
  const birthDate = dayjs(birthday)

  const years = now.diff(birthDate, 'year')
  const months = now.diff(birthDate.add(years, 'year'), 'month')

  const yearText =
    years > 0 ? i18n.t('time.year', { count: years }) : Strings.empty()
  const monthText =
    months > 0 ? i18n.t('time.month', { count: months }) : Strings.empty()
  const andText =
    yearText && monthText ? ` ${i18n.t('time.and')} ` : Strings.empty()

  return `${yearText}${andText}${monthText}`
}

function HospitalizationData({ icon, value }: { icon: string; value: string }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Icon path={icon} size={1} />
      <Typography variant="h5" lineHeight="16px">
        {value}
      </Typography>
    </Stack>
  )
}

export function HospitalizationPetDetail() {
  const { t } = useTranslation()
  const { hospitalization } = useHospitalizationContext()

  return (
    <Stack padding="12px 12px 0 12px" spacing={2}>
      <Stack direction="row" spacing={2}>
        <Avatar
          alt="Pet name"
          src="https://image.petmd.com/files/styles/863x625/public/CANS_dogsmiling_379727605.jpg"
          sx={{ width: '32px', height: '32px' }}
        />
        <Typography variant="h5" fontWeight={700} lineHeight="16px">
          {hospitalization?.pet?.name}
        </Typography>
      </Stack>
      <HospitalizationData
        icon={mdiDog}
        value={`${t('pet_species')} - ${hospitalization?.pet?.species ?? Strings.empty()}`}
      />
      {hospitalization?.pet?.weight ? (
        <HospitalizationData
          icon={mdiScale}
          value={`${hospitalization.pet.weight} kg`}
        />
      ) : null}
      {hospitalization?.pet?.birthday ? (
        <HospitalizationData
          icon={mdiCakeVariant}
          value={formatAge(hospitalization.pet.birthday)}
        />
      ) : null}
    </Stack>
  )
}
