import {
  HttpRequestError,
  NetworkError,
  StatusCode,
  isError,
} from '@boommed-suite/typescript-crossplatform'
import { zodResolver } from '@hookform/resolvers/zod'
import { mdiClose } from '@mdi/js'
import MDIIcon from '@mdi/react'
import { Box, Button, Drawer, Stack, Typography } from '@mui/material'
import { useInjection } from 'inversify-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { BoommedService } from '../../../../../domain/services/BoommedService'
import { Crashlytics } from '../../../../../domain/services/Crashlytics'
import { useAppNavigate } from '../../../../app/AppRouter'
import { useAppContext } from '../../../../app/contexts/AppContext'
import { useNotificationContext } from '../../../../app/contexts/NotificationContext'
import { theme } from '../../../../app/Theme'
import { CustomMUIForm } from '../../../../components/CustomMUIForm/CustomMUIForm'
import { SuiteButton } from '../../../../components/SuiteButton/SuiteButton'
import {
  NewUserForm,
  NewUserFormSchema,
  newUserFormSchema,
} from '../NewUserForm/NewUserForm'
import { styles } from '../NewUserForm/NewUserForm.styles'

interface SubmitValues {
  email: string
  roles: { name: string }[]
}

const NewUserHeader = () => {
  const { t } = useTranslation()
  const navigate = useAppNavigate()
  const onClose = () => {
    navigate(-1)
  }

  return (
    <Stack direction="row" sx={styles.header}>
      <Typography variant="h4" flexGrow={1} fontWeight="bold">
        {t('users_add')}
      </Typography>
      <Box sx={styles.closeButton} onClick={onClose}>
        <MDIIcon path={mdiClose} size={0.75} />
      </Box>
    </Stack>
  )
}

const NewUserFooter = () => {
  const navigate = useAppNavigate()
  const { t } = useTranslation()
  const onClose = () => {
    navigate(-1)
  }

  return (
    <Stack
      sx={styles.footerContainer}
      direction="row"
      spacing={theme.spacing(2)}
    >
      <Box sx={styles.footerDiscardContainer}>
        <Button variant="text" onClick={onClose}>
          {t('discard')}
        </Button>
      </Box>

      <SuiteButton variant="contained" type="submit">
        {t('users_new_create')}
      </SuiteButton>
    </Stack>
  )
}

export function AddUserPage() {
  const navigate = useAppNavigate()
  const { openNotification } = useNotificationContext()
  const boommedService = useInjection(BoommedService)
  const { menu } = useAppContext()
  const { t } = useTranslation()
  const crashlytics = useInjection(Crashlytics)
  const { action: routeAction } = useParams()

  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }

  const addUser = useCallback(async ({ email, roles }: SubmitValues) => {
    const newUserLink = menu?.items?.users?._links?.new

    if (newUserLink === undefined) {
      crashlytics.error(new Error('New user link not found'))
      openNotification({
        severity: 'error',
        text: t('user_add_error'),
      })
      return
    }

    const [result, error] = await boommedService.fetch(newUserLink, {
      email,
      roles: roles.map(({ name }) => name),
    })

    if (
      isError(HttpRequestError)(error) &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      error.statusCode === StatusCode.BAD_REQUEST
    ) {
      openNotification({
        severity: 'error',
        text: t('user_duplicate_error'),
      })
      return
    }

    if (isError(NetworkError)(error)) {
      setUnexpectedError(error)
      return
    }

    if (isError(Error)(error) || isError(HttpRequestError)(error)) {
      crashlytics.error(error)
      openNotification({
        severity: 'error',
        text: t('user_add_error'),
      })
      return
    }

    if (result) {
      navigate(-1)
    }
  }, [])

  const onSubmit = (values: NewUserFormSchema) => {
    void addUser(values)
  }

  return (
    <Drawer
      anchor="right"
      open={routeAction === 'add'}
      sx={styles.drawer}
      variant="temporary"
    >
      <Box sx={styles.container}>
        <NewUserHeader />
        <CustomMUIForm
          resolver={zodResolver(newUserFormSchema)}
          onSubmit={onSubmit}
        >
          <NewUserForm />
          <NewUserFooter />
        </CustomMUIForm>
      </Box>
    </Drawer>
  )
}
