import { UserApi } from '@boommed-suite/contracts'
import { Strings } from '@boommed-suite/typescript-crossplatform'
import { Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import z from 'zod'
import { useAppContext } from '../../../../app/contexts/AppContext'
import i18n from '../../../../app/i18n/i18n'
import { CustomFormAutoComplete } from '../../../../components/CustomMUIForm/CustomFormAutoComplete'
import { CustomFormTextField } from '../../../../components/CustomMUIForm/CustomFormTextField'
import { styles } from './NewUserForm.styles'

export const newUserFormSchema = z.object({
  email: z
    .string()
    .min(1, { message: `${i18n.t('email')} ${i18n.t('is_required')}` })
    .email(i18n.t('invalid_email')),
  roles: z
    .array(z.object({ name: z.string() }), {
      message: `${i18n.t('roles')} ${i18n.t('is_required')}`,
    })
    .min(1, { message: `${i18n.t('roles')} ${i18n.t('is_required')}` }),
})
export type NewUserFormSchema = z.infer<typeof newUserFormSchema>

export function NewUserForm() {
  const { t } = useTranslation()
  const { menu } = useAppContext()

  const {
    email: emailTemplate,
    roles: rolesTemplate,
  }: Record<string, UserApi.TemplateField> =
    menu?.items?.users?.data?.templates?.new || {}

  return (
    <Stack direction="column" sx={styles.form} spacing={2}>
      {emailTemplate && (
        <CustomFormTextField
          name="email"
          label={t(emailTemplate.label)}
          required={emailTemplate.required}
        />
      )}
      {rolesTemplate && (
        <CustomFormAutoComplete
          name="roles"
          label={t(rolesTemplate.label)}
          loadingText={t('loading')}
          noOptionsText={t('roles_not_found')}
          getOptionLabel={(option: { name: string; description?: string }) =>
            t(option.name)
          }
          isOptionEqualToValue={(option, value) => option === value}
          renderOption={(props, option) => {
            return (
              <li {...{ ...props, key: undefined }} key={option.name}>
                {t(option.name)}
                {option.description ? ' | ' : Strings.empty()}
                {t(option.description || Strings.empty())}
              </li>
            )
          }}
          options={rolesTemplate.values?.map((value) => ({
            name: value.name,
            description: value.description,
          }))}
          multiple
          required={rolesTemplate.required}
          disableCloseOnSelect={true}
        />
      )}
    </Stack>
  )
}
