import { SuiteApi } from '@boommed-suite/contracts'
import { Guid, HttpMethods } from '@boommed-suite/typescript-crossplatform'
import dayjs from 'dayjs'
import React, { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useService } from '../../hooks/useService'
interface HospitalizationStore {
  loading: boolean
  hospitalization?: SuiteApi.HospitalizationResponse
}

const HospitalizationContext = createContext<HospitalizationStore>({
  loading: false,
})

export function HospitalizationProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { hospitalizationId } = useParams()

  const { fetching, data: hospitalization } =
    useService<SuiteApi.HospitalizationResponse>(
      {
        service: async (): Promise<
          SuiteApi.HospitalizationResponse | undefined
        > => {
          // TODO: Fetch hospitalization data

          await new Promise((resolve) => setTimeout(resolve, 1000))

          return {
            id: Guid.New().toString(),
            pet: {
              id: Guid.New().toString(),
              name: 'Pepper',
              species: 'Dog',
              weight: 10,
              birthday: dayjs()
                .subtract(10, 'year')
                .subtract(2, 'month')
                .valueOf(),
            },
            client: {
              id: Guid.New().toString(),
              userId: Guid.New().toString(),
              name: 'John Doe',
              phoneNumber: ['+351 912345678'],
              _links: {
                self: {
                  href: `/suite/client/${Guid.New().toString()}`,
                  method: HttpMethods.GET,
                  authRequired: true,
                },
                update: {
                  href: `/suite/client/${Guid.New().toString()}`,
                  method: HttpMethods.PUT,
                  authRequired: true,
                },
                delete: {
                  href: `/suite/client/${Guid.New().toString()}`,
                  method: HttpMethods.DELETE,
                  authRequired: true,
                },
              },
            },
            treatments: [
              {
                title: 'Temperature',
                values: [
                  {
                    timestamp: dayjs().hour(3).minute(0).second(0).valueOf(),
                    value: '38.5°',
                  },
                  {
                    timestamp: dayjs().hour(11).minute(0).second(0).valueOf(),
                    value: '38.5°',
                  },
                  {
                    timestamp: dayjs().hour(12).minute(0).second(0).valueOf(),
                    value: '38.5°',
                  },
                  {
                    timestamp: dayjs().hour(20).minute(0).second(0).valueOf(),
                    value: '37.2°',
                  },
                  {
                    timestamp: dayjs().hour(20).minute(12).second(0).valueOf(),
                    value: '39.5°',
                  },
                ],
              },
              {
                title: 'Temperature 2',
                values: [
                  {
                    timestamp: dayjs().hour(3).minute(0).second(0).valueOf(),
                    value: '38.5°',
                  },
                  {
                    timestamp: dayjs().hour(11).minute(0).second(0).valueOf(),
                    value: '38.5°',
                  },
                  {
                    timestamp: dayjs().hour(12).minute(0).second(0).valueOf(),
                    value: '38.5°',
                  },
                  {
                    timestamp: dayjs().hour(20).minute(0).second(0).valueOf(),
                    value: '37.2°',
                  },
                  {
                    timestamp: dayjs().hour(20).minute(12).second(0).valueOf(),
                    value: '39.5°',
                  },
                ],
              },
            ],
          }
        },
      },
      [hospitalizationId],
    )

  return (
    <HospitalizationContext.Provider
      value={{ loading: fetching, hospitalization }}
    >
      {children}
    </HospitalizationContext.Provider>
  )
}

export const useHospitalizationContext = () =>
  useContext(HospitalizationContext)
