import { DependencyList, useEffect } from 'react';

export const useInterval = (
  { executor, delay = 1000 }: { executor: () => void; delay?: number },
  dependencies: DependencyList = [],
) => {
  return useEffect(() => {
    const interval = setInterval(executor, delay)
    return () => clearInterval(interval)
  }, dependencies)
}
