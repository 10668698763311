import dayjs from 'dayjs'
import React, { createContext, useContext } from 'react'
import { useCurrentTime } from '../../hooks/useCurrentTime'

interface CurrentTimeStore {
  currentTime: dayjs.Dayjs
}

const CurrentTimeContext = createContext<CurrentTimeStore>({
  currentTime: dayjs(),
})

export function CurrentTimeProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const currentTime = useCurrentTime()

  return (
    <CurrentTimeContext.Provider value={{ currentTime }}>
      {children}
    </CurrentTimeContext.Provider>
  )
}

export const useCurrentTimeContext = () => useContext(CurrentTimeContext)
