import { Strings } from '@boommed-suite/typescript-crossplatform'
import { Grid2, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TruncatedTypography } from '../../../../components/TruncatedTypography'
import { useHospitalizationContext } from '../../HospitalizationContext'

function Detail({
  label,
  value = Strings.empty(),
}: {
  label: string
  value?: string
}) {
  return (
    <Stack width="183px" direction="row" whiteSpace="nowrap" spacing={2}>
      <Typography fontWeight={500} lineHeight="14px" variant="h6">
        {label}
      </Typography>
      <TruncatedTypography
        fontWeight={400}
        lineHeight="14px"
        variant="h6"
        textAlign="right"
        flexGrow={1}
      >
        {value}
      </TruncatedTypography>
    </Stack>
  )
}

export function HospitalizationClientDetail() {
  const { t } = useTranslation()
  const { hospitalization } = useHospitalizationContext()

  return (
    <Grid2 container spacing={2} paddingX="12px" flexDirection="column">
      <Grid2>
        <Typography fontWeight={700} lineHeight="16px" variant="h5">
          {t('client')}
        </Typography>
      </Grid2>
      {hospitalization?.client?.name?.trim() && (
        <Detail
          label={t('client_name')}
          value={hospitalization?.client?.name}
        />
      )}
      {hospitalization?.client?.phoneNumber?.[0] && (
        <Detail
          label={t('client_phone_number')}
          value={hospitalization?.client?.phoneNumber?.[0]}
        />
      )}
    </Grid2>
  )
}
